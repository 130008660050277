import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul className="work-links">
    <li>
        <a href="https://sage.kajabi.com">
            <i className="fas fa-globe-americas fa-lg"></i>
            View site &raquo;
        </a>
    </li>
    </ul>
    <p>{`At Kajabi, we are building a design system from the ground up. The system is still work in progress as it becomes more and more incorporated into use in the customer's administrative system. At the time of this posting the system supported both Ruby on Rails and React components.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/thumb-sage.png",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/images/work/sage-component.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      